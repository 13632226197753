/* apply a natural box layout model to all elements, but allowing components to change
http://www.paulirish.com/2012/box-sizing-border-box-ftw/; 
*/
:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.logo {
  height: 32px;
}

.req-guidance-outline #label {
  outline: solid;
  outline-width: 1px;
  outline-color: red;
  padding: 8px;
  margin-bottom: 8px;
}

.rc-time-picker {
  @apply border-none;
}
.rc-time-picker-input {
  @apply border-none text-base mt-1 font-sans;
  background: none;
  height:  30px;

}
.rc-time-picker-panel-input {
  @apply text-base font-sans;
}
.rc-time-picker-panel-select {
  @apply text-base font-sans;
}
.rc-time-picker-panel-select {
  text-transform: uppercase;
}
.rc-time-picker-clear {
  @apply text-xl;
  height:  30px;
  outline: none;
}
.rc-time-picker-clear-icon::after {
  font-size: inherit;
}

